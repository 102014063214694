/* eslint-disable valid-typeof */
import * as moment from 'moment'
import { format } from 'date-fns'

export const getLeaveTimeDate = datetime => {
	if (!!datetime) {
		datetime = datetime.replace('.000Z', '')
		return format(new Date(datetime), 'dd MMM yyyy, hh:mm a')
	} else {
		const utcTime = format(new Date(), 'dd MMM yyyy, hh:mm a')
		return utcTime
	}
}
export const getCurrentDateISTWidgetDash = datetime => {
	if (typeof datetime === 'undefined') return moment().format('YYYY-MM-DD')
	else return moment(datetime).format('YYYY-MM-DD')
}

export const getStartOfMonthISTWidgetDash = datetime => {
	if (typeof datetime === 'undefined')
		return moment().startOf('month').format('YYYY-MM-DD')
	else return moment(datetime).startOf('month').format('YYYY-MM-DD')
}

export const getEndOfMonthISTWidgetDash = datetime => {
	if (typeof datetime === 'undefined')
		return moment().endOf('month').format('YYYY-MM-DD')
	else return moment(datetime).endOf('month').format('YYYY-MM-DD')
}

// add no of days to current date FORMAT YYYY-MM-DD
export const getAddToCurrentDate = (startdate, days) => {
	return moment(startdate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD')
}

export const getCurrentDateISTWidget = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('YYYY-MM-DD 00:00:00')
	else return moment(datetime).format('YYYY-MM-DD 00:00:00')
}

export const getCurrentDateUTCSTART = datetime => {
	if (typeof datetime === undefined)
		return moment().utc().format('YYYY-MM-DD') + ' 00:00:00'
	else return moment(datetime).utc().format('YYYY-MM-DD') + ' 00:00:00'
}

export const getCurrentDateUTCEOD = datetime => {
	if (typeof datetime === undefined)
		return moment().utc().format('YYYY-MM-DD') + ' 23:59:59'
	else return moment(datetime).utc().format('YYYY-MM-DD') + ' 23:59:59'
}
export const getDateISTFormat = datetime => {
	if (typeof datetime === undefined)
		return moment().format('YYYY-MM-DD') + ' 00:00:00'
	else return moment(datetime).format('YYYY-MM-DD') + ' 00:00:00'
}

export const getUTCtoIST = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('YYYY-MM-DD HH:mm:ss')
	else return moment(datetime).add(330, 'minutes').format('YYYY-MM-DD HH:mm:ss')
}

export const timelineDateFormat = datetime => {
	return moment(datetime).format('llll')
}

export const getCurrentDateUTC = datetime => {
	if (typeof datetime === 'undefined')
		return moment().utc().format('YYYY-MM-DD HH:mm:ss')
	else return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss')
}

export const getCurrentDateFromDateTime = datetime => {
	if (typeof datetime === 'undefined') return moment().format('YYYY-MM-DD')
	else return moment(datetime).format('YYYY-MM-DD')
}

// add no of days to current date
export const getAddToCurrentDateUTC = (startdate, days) => {
	return moment(startdate, 'YYYY-MM-DD HH:mm:ss')
		.add(days, 'days')
		.format('YYYY-MM-DD HH:mm:ss')
}

export const getAddToCurrentDateOnlyUTC = (startdate, days) => {
	return moment(startdate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD')
}

export const formatNoteUpdateDate = datetime => {
	if (typeof datetime === 'undefined') return moment().format('Do MMM ')
	else return moment(datetime).format('Do MMM ')
}

export const getYear = date => {
	return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY')
}

export const getMonth = date => {
	return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM')
}

export const formatDueDateForTimeline = datetime => {
	if (typeof datetime === 'undefined') return moment().format('Do MMM YYYY')
	else return moment(datetime).format('Do MMM YYYY')
}

export const formatDueDateForTimelineCurrDate = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('DD MMM YYYY, h:mm A')
	else return moment(datetime).format('DD MMM YYYY, h:mm A')
}

export const getCurrentDate = () => {
	return moment().toDate()
}

export const getStartOfDay = datetime => {
	if (typeof datetime === 'undefined') return moment().startOf('day').toDate()
	else return moment(datetime).startOf('day').toDate()
}

export const getEndOfDay = datetime => {
	if (typeof datetime === 'undefined') return moment().endOf('day').toDate()
	else return moment(datetime).endOf('day').toDate()
}

export const getDifferenceInDays = (datetime1, datetime2) => {
	if (typeof datetime1 === 'undefined' || typeof datetime2 === 'undefined')
		return 0
	let a = moment(datetime1)
	let b = moment(datetime2)
	return Math.abs(a.diff(b, 'days'))
}

export const getStartDateOfMonth = datetime => {
	if (typeof datetime === 'undefined') return moment().startOf('month').toDate()
	else return moment(datetime).startOf('month').toDate()
}

export const getEndDateOfMonth = datetime => {
	if (typeof datetime === 'undefined') return moment().endOf('month').toDate()
	else return moment(datetime).endOf('month').toDate()
}

export const getStartOfPastMonth = (value = 1) => {
	return moment().subtract(value, 'M').startOf('month').toDate()
}

export const getEndOfPastMonth = (value = 1) => {
	return moment().subtract(value, 'M').endOf('month').toDate()
}

export const getStartOfNextMonth = (value = 1) => {
	return moment().add(value, 'M').startOf('month').toDate()
}

export const getEndOfNextMonth = (datetime, value = 1) => {
	if (typeof datetime === 'undefined')
		return moment(datetime).add(value, 'M').endOf('day').toDate()
	else return moment().add(value, 'M').endOf('month').toDate()
}

export const getStartofCurrentQuater = () => {
	return moment().quarter(moment().quarter()).startOf('quarter').toDate()
}

export const getEndofCurrentQuater = () => {
	return moment().quarter(moment().quarter()).endOf('quarter').toDate()
}

export const getDateSubtracted = (datetime, type = 'years', value = 1) => {
	if (typeof datetime === 'undefined')
		return moment().subtract(value, type).toDate()
	else return moment(datetime).subtract(value, type).toDate()
}

export const getStartofPastQuater = (value = 1) => {
	return moment().subtract(value, 'Q').startOf('quarter').toDate()
}

export const getEndofPastQuater = (value = 1) => {
	return moment().subtract(value, 'Q').endOf('quarter').toDate()
}

export const getStartofNextQuater = (value = 1) => {
	return moment().add(value, 'Q').startOf('quarter').toDate()
}

export const getEndofNextQuater = (value = 1) => {
	return moment().add(value, 'Q').endOf('quarter').toDate()
}

export const getStartOfRealtime = () => {
	return moment().year(1970).startOf('year').toDate()
}

export const getStartDateOfQuarter = () => {
	let quarterNum = moment().quarter()
	switch (quarterNum) {
		case 1:
			return '01-01'
		case 2:
			return '04-01'
		case 3:
			return '07-01'
		case 4:
			return '10-01'
		default:
			break
	}
}

export const getSecondsToDays = x => {
	let totalValue = x / 86400
	return totalValue.toFixed(2)
}

export const getMondayOfAWeek = datetime => {
	if (typeof datetime === 'undefined') {
		let date = moment().format('YYYY-MM-DD HH:mm:ss')
		let begin = moment(date).startOf('week').isoWeekday(1)
		return begin.format('YYYY-MM-DD HH:mm:ss')
	} else {
		return moment(datetime)
			.startOf('week')
			.add(1, 'days')
			.format('YYYY-MM-DD HH:mm:ss')
	}
}

export const getFormatDDMMYYYY = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD-MM-YYYY')
	else return moment(datetime).format('DD-MM-YYYY')
}

export const getSecondsToHours = x => {
	let totalValue = x / 3600 //Hours
	return totalValue.toFixed(2)
}

export const getCustomFormatttedDate = (datetime, dateFormat) => {
	if (datetime !== null) {
		if (typeof datetime === 'undefined') return ''
		else return moment(datetime).format(dateFormat)
	} else {
		return ''
	}
}

export const formatExportCoverDate = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD/MM/YYYY')
	else return moment(datetime).add(330, 'minutes').format('DD/MM/YYYY')
}

export const formatDDMMYYYYHHMM = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('DD-MM-YYYY HH:mm')
	else return moment(datetime).format('DD-MM-YYYY HH:mm')
}

export const formatDateTime = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('YYYY-MM-DD HH:mm:ss')
	else return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
}

export const getISTtoUTC = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('YYYY-MM-DD HH:mm:ss')
	else
		return moment(datetime)
			.subtract(330, 'minutes')
			.format('YYYY-MM-DD HH:mm:ss')
}

export const getReportDatteISTtoUTC = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD/MM/YYYY')
	else return moment(datetime).subtract(330, 'minutes').format('DD/MM/YYYY')
}

export const getFromNow = timestamp => {
	const timeNew = moment.utc(timestamp).fromNow()
	const convTime = timeNew
		.split(' ')
		.map(item => (timeConvertions[item] ? timeConvertions[item] : item))
		.join(' ')
	return convTime
}

const timeConvertions = {
	minutes: 'min',
	seconds: 'sec',
	minute: 'min',
}

export const formatHHMMSS = datetime => {
	if (typeof datetime === 'undefined') return moment().format('HH:mm:ss')
	else return moment(datetime).format('HH:mm:ss')
}

export const formatDDMMYYYY = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD-MM-YYYY')
	else return moment(datetime).format('DD-MM-YYYY')
}

export const getAddHoursToCurrentDateUTC = (startdate, hours) => {
	return moment(startdate, 'YYYY-MM-DD HH:mm:ss')
		.add(hours, 'hours')
		.format('YYYY-MM-DD HH:mm:ss')
}

export const getDeclineTimeDate = datetime => {
	if (!!datetime) {
		datetime = datetime.replace('.000Z', '')
		datetime = moment(datetime).add(330, 'minutes')
		return format(new Date(datetime), 'dd MMM yyyy, hh:mm a')
	} else {
		const utcTime = format(new Date(), 'dd MMM yyyy, hh:mm a')
		return utcTime
	}
}

export const formatInvoiceMMMDDYYYY = datetime => {
	if (typeof datetime === 'undefined') return ''
	else return moment(datetime).format('MMMM DD, YYYY')
}

export const formatInvoicePODate = datetime => {
	if (typeof datetime === 'undefined') return ''
	else return moment(datetime).format('DD/MM/YYYY')
}
