export const detectURL = message => {
	let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
	return message.match(urlRegex)
}

export const replaceURL = message => {
	if (!message) return

	let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
	return message.replace(urlRegex, function (url) {
		let hyperlink = url
		if (!hyperlink.match('^https?://')) {
			hyperlink = 'http://' + hyperlink
		}
		return (
			'<a href="' +
			hyperlink +
			'" target="_blank" rel="noopener noreferrer" style = "color:green">' +
			url +
			'</a>'
		)
	})
}
export const convertPound = input => {
	let message = input.replace(/\\243(\w{0,0})/g, function (a, b) {
		return String.fromCharCode(163)
	})
	let detectURLs = detectURL(message)
	if (!!detectURLs) {
		let messageWithURL = replaceURL(message)
		return messageWithURL
	} else return message
}

export const convertUnicode = input => {
	return input.replace(/\\u(\w{4,4})/g, function (a, b) {
		var charcode = parseInt(b, 16)
		return String.fromCharCode(charcode)
	})
}
