/* eslint-disable array-callback-return */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
import { groupBy, sortBy } from 'lodash'
//import * as XLSX from 'js-xlsx-map'
import * as XLSX from 'xlsx'
import { getMessageUniqueId, getLocalAuthDetails } from './common.util'
import {
	formatDDMMYYYYHHMM,
	getAddToCurrentDateUTC,
	getCurrentDateFromDateTime,
	getCurrentDateUTC,
} from './date.utils'
import calcStub from './vil_form_config.util'

export const validateExcelBot = ({
	schema,
	file,
	fieldList,
	onSuccess,
	finalFormList,
	onError,
	toggleLoader,
}) => {
	// Return if no schema found to validate
	if (!schema.length) {
		onSuccess([file], [])
		return
	}
	const fieldId = schema[0].field_id
	let comboId
	if (
		!!finalFormList &&
		!!finalFormList.list &&
		finalFormList.list.length > 0
	) {
		let dependentField = (finalFormList.list || []).find(item => {
			return Number(fieldId) === Number(item.field_id)
		})
		comboId = dependentField.data_type_combo_id
	} else {
		comboId = fieldList[`${fieldId}i`].selectedOption.comboId
	}

	const validationCell = schema.find(valItem => {
		if (
			valItem.field_id === fieldId &&
			valItem.data_type_combo_id === comboId
		) {
			return valItem
		}
	})
	if (
		schema.length > 0 &&
		!!validationCell &&
		Object.keys(validationCell).length > 0
	) {
		toggleLoader(true)
		const validationCellData = validationCell.validation_cells
		const fileReader = new FileReader()
		try {
			fileReader.onload = function (e) {
				let isValid = true
				let data = e.target.result
				try {
					let workbook = XLSX.read(data, { type: 'binary', bookVBA: true })
					;(validationCellData || []).forEach(cell => {
						const sheetName = workbook.SheetNames[0]
						const sheetData = workbook.Sheets[sheetName]
						const cellKey = `${cell.cell_x}${cell.cell_y}`.toUpperCase()
						const { v } = sheetData[cellKey] || {}
						if (cell && cell.cell_value && !!v) {
							let cellValue = typeof v === 'string' ? v.trim() : v
							if (cellValue !== cell.cell_value.trim()) {
								isValid = false
							}
						} else if (!v) {
							isValid = false
						}
					})
					if (isValid) {
						toggleLoader(false)
						onSuccess([file], [])
					} else {
						toggleLoader(false)
						onError()
					}
				} catch (err) {
					toggleLoader(false)
					onError()
				}
			}
		} catch (err) {
			toggleLoader(false)
		}
		fileReader.readAsBinaryString(file)
	} else {
		onSuccess([file], [])
	}
}

export const processFormFields = fieldList => {
	let finalList = []
	const selectionFields = []
	const otherFields = []
	fieldList?.forEach(field => {
		if (field.data_type_id === 33 || field.data_type_id === 34) {
			selectionFields.push(field)
		} else {
			otherFields.push(field)
		}
	})
	const { organization_flag_enable_grid } = getLocalAuthDetails()
	const groupFields = groupBy(selectionFields, 'field_id')
	if (Object.keys(groupFields).length > 0) {
		Object.keys(groupFields)?.forEach(fieldId => {
			let fld = fieldList.find(d => String(d.field_id) === fieldId)
			const options = groupFields[fieldId].map((f, index) => {
				if (
					f.field_inline_data &&
					f.field_inline_data.hasOwnProperty('multiplier')
				) {
					return {
						name: f.data_type_combo_value,
						label: f.data_type_combo_value,
						value: f.data_type_combo_value,
						comboId: f.data_type_combo_id,
						comboNextFieldId: f.next_field_id,
						// data_type_combo_id: index + 1,  //todo :-- data_type_combo_id not same as comboid
						multiplier: f.field_inline_data.multiplier,
						data_type_combo_id: f.data_type_combo_id,
						field_rag_status_name: f.field_rag_status_name,
						field_flag_is_default: f.field_flag_is_default,
					}
				} else {
					return {
						name: f.data_type_combo_value,
						label: f.data_type_combo_value,
						value: f.data_type_combo_value,
						comboId: f.data_type_combo_id,
						comboNextFieldId: f.next_field_id,
						// data_type_combo_id: index + 1,  //todo :-- data_type_combo_id not same as comboid
						data_type_combo_id: f.data_type_combo_id,
						field_rag_status_name: f.field_rag_status_name,
						field_flag_is_default: f.field_flag_is_default,
					}
				}
			})
			if (!!fld && fld.data_type_id === 33 && options.length > 1) {
				options.unshift({
					name: 'Select',
					label: 'Select',
					value: '',
					data_type_combo_id: 0,
				})
			}
			finalList.push({ ...groupFields[fieldId][0], options })
		})
	}

	finalList = [...finalList, ...otherFields]

	return sortBy(finalList, ['field_sequence_id'])
}

export const accountFormConfig = {
	868: {
		organization: 'VIL',
		accountNameConfig: {
			150011: {
				name: 'Group Account',
				form_id: 3036,
			},
		},
		accountCodeConfig: {
			149277: {
				name: 'Account management - LA',
				form_id: 3778, // id for the form where we have to trigger the account code generation
			},
			150442: {
				name: 'Account management - GE',
				form_id: 3779, // id for the form where we have to trigger the account code generation
			},
			150443: {
				name: 'Account management - GOVT',
				form_id: 3780, // id for the form where we have to trigger the account code generation
			},
			150254: {
				name: 'Carrier partner addition - VICS',
				form_id: 50104, // please wait for id
			},
			149809: {
				name: 'Account management - SME',
				form_id: [3781, 2810, 4651], // this form is depending on some other forms to generate code
			},
			150444: {
				name: 'Account management - SOHO',
				form_id: [3782, 4678, 4679], // this form is depending on some other forms to generate code
			},
		},
	},
}

export const accountCode = {
	ACCOUNT_CODE: 'ACCOUNT_CODE',
	ACCOUNT_NAME: 'ACCOUNT_NAME',
}

export const loadFinalValue = (field, value, options = {}) => {
	const {
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
	} = field
	let finalValue
	let fieldValue
	if (field.data_type_id === 2) {
		fieldValue = getCurrentDateFromDateTime(value)
		finalValue = getCurrentDateFromDateTime(value)
	} else if (field.data_type_id === 71) {
		// Product Cart
		const {
			field_inline_data: { product_cart_config = {} },
		} = field
		let d_flag =
			product_cart_config?.flag_enable_double_quantity_calculation === 1
		const {
			productVariant,
			productTotal,
			caseTotal,
			BottleTotal,
			totalQuantity,
			productWorkflow,
			productCat,
			productWorkflowType,
			isFromEdit,
		} = value

		let items =
			productVariant && productVariant.length
				? productVariant.map(variant => {
						let exp = {}
						if (d_flag) {
							exp = {
								product_variant_quantity_1: variant?.quantity_1,
								product_variant_quantity_2: variant?.quantity_2,
							}
						}
						return {
							product_variant_activity_id: variant.value,
							product_variant_activity_title: variant.label,
							product_variant_sku_code: variant.details.activity_cuid_2,
							product_variant_unit_price: variant.details.activity_cuid_3,
							product_variant_quantity: variant.quantity,
							product_variant_total_cost: variant.productPrice,
							...exp,
						}
				  })
				: []
		let inline_data = {
			product_tag_type_id: productCat ? productCat.details.tag_type_id : '',
			product_tag_type_name: productCat ? productCat.details.tag_type_name : '',
			product_tag_id: productCat?.value,
			product_tag_name: productCat?.label,
			product_activity_type_id: productWorkflow?.details.activity_type_id,
			product_activity_type_name: productWorkflow?.details.activity_type_name,
			product_activity_id: productWorkflow?.value,
			product_activity_title: productWorkflow?.label,
			product_activity_business_case:
				productWorkflow?.details.activity_workbook_url,
			cart_items: items,
			cart_total_cost: productTotal,
			total_double_quantity_lable_2: BottleTotal,
			total_double_quantity_lable_1: caseTotal,
			cart_total_quantity: totalQuantity,
		}
		finalValue = JSON.stringify(inline_data)
		fieldValue = {
			total: productTotal,
			variants: productVariant,
			productCatData: productCat,
			productWorkflowData: productWorkflow,
			productWorkflowTypeData: productWorkflowType,
			productVariantData: productVariant,
			productTotalData: productTotal,
			isFromEdit,
		}
	} else if (field.data_type_id === 82) {
		// Conveyance
		const { productVariant, productTotal, productCat, isFromEdit } = value
		let items =
			productVariant && productVariant.length
				? productVariant.map(variant => {
						return {
							mode_id: variant.value,
							mode_name: variant.label,
							distance: variant.distance,
							price: variant.price,
						}
				  })
				: []
		let inline_data = {
			is_on_holiday: 0,
			modes: items,
			total_distance: 0,
			total_price: productTotal,
		}
		finalValue = JSON.stringify(inline_data)
		fieldValue = {
			total: productTotal,
			variants: productVariant,
			productCatData: productCat,
			productVariantData: productVariant,
			productTotalData: productTotal,
			isFromEdit,
		}
	} else if (field.data_type_id === 66) {
		const {
			document_title,
			document_description,
			document_version,
			document_url,
			document_id,
			productWorkflow,
		} = value
		let formattedValue = {
			product_reference: JSON.stringify(productWorkflow),
			document_title: document_title,
			document_description: document_description,
			document_version: document_version,
			url_path: document_url,
			document_id: document_id,
		}
		finalValue = JSON.stringify(formattedValue)
		fieldValue = {
			product_reference: JSON.stringify(productWorkflow),
			document_title: document_title,
			document_description: document_description,
			document_version: document_version,
			url_path: document_url,
			document_id: document_id,
		}
	} else if (field.data_type_id === 70) {
		finalValue = value.value
		fieldValue = value
	} else if (field.data_type_id === 77) {
		finalValue = JSON.stringify(value)
		fieldValue = value
	} else if (field.data_type_id === 19) {
		// fieldValue = toConvertSentenceCase(value);
	} else if (field.data_type_id === 18) {
		const { field_inline_data } = field
		let values = {}
		try {
			values = {
				code: field_inline_data?.currency_restriction?.currency_name,
				symbol: field_inline_data?.currency_restriction?.currency_symbol,
				value: value.includes('code') ? JSON.parse(value)?.value : value,
			}
		} catch (e) {
			values = {
				code: field_inline_data?.currency_restriction?.currency_name,
				symbol: field_inline_data?.currency_restriction?.currency_symbol,
				value: value,
			}
		}
		finalValue = values
		fieldValue = value
	} else if (field.data_type_id === 79) {
		fieldValue = value
		let fvalue = value.map(val => val.details)
		finalValue = JSON.stringify(fvalue)
	} else if (field.data_type_id === 80) {
		fieldValue = value
		finalValue = JSON.stringify(value)
	} else if (field.data_type_id === 74) {
		fieldValue = value
		finalValue = JSON.stringify(value)
	} else if (field.data_type_id === 81) {
		fieldValue = value
		finalValue = JSON.stringify(value)
	} else if (field.data_type_id === 68) {
		fieldValue = value
		finalValue = JSON.stringify(value.map(i => i.details_web))
	} else if (field.data_type_id === 69) {
		fieldValue = value
		finalValue = JSON.stringify(value.map(i => i.details))
	} else if (field.data_type_id === 34) {
		fieldValue = value
		finalValue = value.map(item => item.value).join('|')
	} else if (field.data_type_id === 75) {
		fieldValue = value
		finalValue = value
	}
	return {
		final: {
			form_id,
			field_id,
			field_reference_id,
			field_name,
			field_data_type_id: data_type_id,
			field_data_type_category_id: data_type_category_id,
			data_type_combo_id:
				!!value && !!value.data_type_combo_id ? value.data_type_combo_id : 0,
			data_type_combo_value: '0',
			message_unique_id: getMessageUniqueId(),
			field_value: finalValue
				? finalValue
				: value && typeof value === 'object'
				? value.value
				: value,
		},
		field_value: fieldValue || value,
		form_transaction_id: field?.form_transaction_id || null,
	}
}

export const prepareFinalFieldValueForEdit = (
	fieldItem,
	field = { options: [] }
) => {
	if (Number(fieldItem.field_data_type_id) === 33) {
		// let option = field.options.find(i => i.data_type_combo_id === fieldItem.data_type_combo_id);
		let option =
			!!field.options && Array.isArray(field.options)
				? field.options.find(
						i => i.label.toLowerCase() === fieldItem.field_value.toLowerCase()
				  )
				: []

		return {
			value: fieldItem.field_value,
			label: fieldItem.field_value,
			...option,
		}
	} else if (Number(fieldItem.field_data_type_id) === 70) {
		return {
			data_type_combo_id: fieldItem.data_type_combo_id,
			value: fieldItem.field_value,
			label: fieldItem.field_value,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 77) {
		let val =
			typeof fieldItem.field_value === 'object'
				? fieldItem.field_value
				: JSON.parse(fieldItem.field_value)
		return {
			...val,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 34) {
		const fieldValue = fieldItem.field_value.split('|')
		// return fieldItem.field_value;
		return fieldValue.map(val => {
			if (val)
				return {
					name: val,
					label: val,
					value: val,
					comboId: 5,
					comboNextFieldId: 0,
				}
		})
	} else if (Number(fieldItem.field_data_type_id) === 59) {
		// TODO: Vasu - multi asset, multi reference, product cart,
		const [
			asset_id,
			operating_asset_first_name,
			operating_asset_id,
			asset_first_name,
		] = fieldItem.field_value.split('|')
		return {
			value: `${asset_id}|${operating_asset_first_name}`,
			label: unescape(operating_asset_first_name),
			details: {
				operating_asset_id,
				asset_first_name,
				asset_id,
				operating_asset_first_name,
			},
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 79) {
		let fieldValues = JSON.parse(fieldItem.field_value)
		let finalArray = []

		if (fieldValues && fieldValues.length > 0) {
			finalArray = fieldValues.map(val => {
				return {
					label: val.lov_name,
					value: val.lov_name,
					details: val,
				}
			})
		}
		return {
			finalArray,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 57) {
		const [
			activity_id,
			activity_title,
			activity_cuid_1,
			activity_cuid_2,
			activity_cuid_3,
			operating_asset_first_name,
		] = fieldItem.field_value.split('|')
		return {
			label: unescape(activity_title),
			value: `${activity_id}|${activity_title}`,
			details: {
				activity_id,
				activity_title,
				activity_cuid_1,
				activity_cuid_2,
				activity_cuid_3,
				operating_asset_first_name,
			},
		}
	} else if (Number(fieldItem.field_data_type_id) === 64) {
		const fieldValue = fieldItem.field_value
		return {
			label: fieldItem.field_name,
			value: fieldValue,
			details: {
				...fieldItem,
			},
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 62) {
		const fieldValue = fieldItem.field_value
		return {
			label: fieldItem.field_name,
			value: fieldValue,
			details: {
				...fieldItem,
			},
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 65) {
		const fieldValue = fieldItem.field_value
		return {
			label: fieldItem.field_name,
			value: fieldValue,
			details: {
				...fieldItem,
			},
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 66) {
		const docParseValues = JSON.parse(fieldItem.field_value)
		const product_reference = JSON.parse(docParseValues.product_reference)
		return {
			...docParseValues,
			product_reference,
		}
	} else if (Number(fieldItem.field_data_type_id) === 69) {
		// multi asset ref
		const parseValues = JSON.parse(fieldItem.field_value)

		return parseValues.map(d => {
			const { asset_id, operating_asset_first_name, ...rest } = d
			return {
				label: operating_asset_first_name,
				value: asset_id,
				details: {
					asset_id,
					operating_asset_first_name,
					...rest,
				},
			}
		})
	} else if (Number(fieldItem.field_data_type_id) === 68) {
		// multi workflow ref
		const parseValues = JSON.parse(fieldItem.field_value)

		return parseValues.map(d => {
			const {
				activity_id,
				activity_title,
				workflow_activity_title,
				workflow_activity_id,
				...rest
			} = d
			return {
				label: activity_title || workflow_activity_title,
				value: activity_id
					? `${activity_id}|${activity_title}`
					: `${workflow_activity_id}|${workflow_activity_title}`,
				details: {
					activity_id: activity_id || workflow_activity_id,
					activity_title: activity_title || workflow_activity_title,
					...rest,
				},
				details_web: {
					activity_id: activity_id || workflow_activity_id,
					activity_title: activity_title || workflow_activity_title,
					...rest,
				},
			}
		})
	} else if (Number(fieldItem.field_data_type_id) === 71) {
		const parseProductValues = JSON.parse(fieldItem.field_value)
		const productCat = {
			details: {
				tag_type_id: parseProductValues.product_tag_type_id,
				tag_type_name: parseProductValues.product_tag_type_name,
			},
			label: parseProductValues.product_tag_name,
			value: parseProductValues.product_tag_id,
		}
		const productWorkflowType = {
			details: {
				tag_id: parseProductValues.product_tag_id,
				tag_type_id: parseProductValues.product_tag_type_id,
			},
			label: parseProductValues.product_activity_type_name,
			value: parseProductValues.product_activity_type_id,
		}
		const productWorkflow = {
			details: {
				activity_datetime_end_deferred: parseProductValues.datetime_end,
				activity_datetime_start_expected: parseProductValues.datetime_start,
				activity_type_id: parseProductValues.product_activity_type_id,
				activity_type_name: parseProductValues.product_activity_type_name,
				activity_workbook_url:
					parseProductValues.product_activity_business_case,
			},
			label: parseProductValues.product_activity_title,
			value: parseProductValues.product_activity_id,
		}
		let cartItems = parseProductValues.cart_items
		if (typeof parseProductValues.cart_items === 'string') {
			cartItems = JSON.parse(parseProductValues.cart_items)
		}
		const productVariant = cartItems.map(cart => {
			return {
				details: {
					activity_cuid_2: cart.product_variant_sku_code,
					activity_cuid_3: cart.product_variant_unit_price,
				},
				label: cart.product_variant_activity_title,
				productPrice: cart.product_variant_total_cost,
				quantity: cart.product_variant_quantity,
				quantity_1: cart?.product_variant_quantity_1,
				quantity_2: cart?.product_variant_quantity_2,
				value: cart.product_variant_activity_id,
			}
		})
		const productTotal = parseProductValues.cart_total_cost
		return {
			productVariant,
			productTotal,
			productWorkflow,
			productCat,
			productWorkflowType,
			productCatData: productCat,
			productWorkflowData: productWorkflow,
			productWorkflowTypeData: productWorkflowType,
			productVariantData: productVariant,
			productTotalData: productTotal,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 82) {
		const parseProductValues = JSON.parse(fieldItem.field_value)
		let cartItems = parseProductValues.modes
		if (typeof parseProductValues.modes === 'string') {
			cartItems = JSON.parse(parseProductValues.modes)
		}
		const productVariant = cartItems.map(variant => {
			return {
				value: variant.mode_id,
				label: variant.mode_name,
				distance: variant.distance,
				price: variant.price,
				productPrice: Number(variant.distance) * Number(variant.price),
			}
		})
		const productCat =
			cartItems.length > 1
				? { label: 'Multiple', value: 2 }
				: { label: 'Single', value: 1 }
		const productTotal = parseProductValues.total_price

		return {
			productCatData: productCat,
			productVariantData: productVariant,
			productTotalData: productTotal,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 74) {
		const comLov =
			typeof fieldItem.field_value === 'string'
				? JSON.parse(fieldItem.field_value)
				: fieldItem.field_value

		if (Array.isArray(comLov)) {
			return {
				list: comLov.map(l => {
					if (Array.isArray(l)) {
						return l.map(d => {
							return { ...d, details: d, label: d.lov_name, value: d.lov_id }
						})
					} else {
						return { ...l, details: l, label: l.lov_name, value: l.lov_id }
					}
				}),
				isFromEdit: true,
			}
		} else {
			return {
				isFromEdit: false,
			}
		}
	} else if (Number(fieldItem.field_data_type_id) === 75) {
		try {
			let json =
				typeof fieldItem.field_value === 'string' &&
				fieldItem.field_value.includes('[')
					? JSON.parse(fieldItem.field_value)
					: fieldItem.field_value
			return {
				json,
				isFromEdit: true,
			}
		} catch (e) {
			return {
				json: [],
				isFromEdit: true,
			}
		}
	} else if (Number(fieldItem.field_data_type_id) === 80) {
		let finalArray = []
		let fieldValues = []
		try {
			fieldValues = JSON.parse(fieldItem.field_value)
		} catch (error) {
			fieldValues = []
		}
		if (fieldValues && fieldValues.length > 0) {
			finalArray = fieldValues.map(val => {
				return val
			})
		}
		return {
			finalArray,
			isFromEdit: true,
		}
	} else if (Number(fieldItem.field_data_type_id) === 18) {
		let data =
			typeof fieldItem.field_value === 'object'
				? !!fieldItem.field_value.value
					? fieldItem.field_value.value
					: fieldItem.field_value
				: !!JSON.parse(fieldItem.field_value)?.value
				? JSON.parse(fieldItem.field_value)?.value
				: fieldItem.field_value
		return data
	} else {
		let data =
			typeof fieldItem.field_value === 'object'
				? JSON.stringify(fieldItem.field_value || {})
				: fieldItem.field_value
		return unescape(data)
	}
}

export const prepareFormSaveParams = ({
	fields,
	fieldList,
	formData,
	authData,
	activityType,
	activityStatus,
}) => {
	const participantInfo = () => {
		const {
			account_id,
			asset_first_name,
			asset_id,
			asset_image_path,
			asset_last_name,
			asset_phone_number,
			asset_phone_number_code,
			field_id,
			operating_asset_first_name,
			organization_id,
			workforce_id,
		} = authData
		return [
			{
				access_role_id: 22,
				asset_datetime_last_seen: '1970-01-01 00:00:00',
				activity_id: formData.activity_id,
				asset_type_category_id: 3,
				asset_type_id: 133001,
				field_id: field_id || 0,
				account_id,
				asset_first_name,
				asset_id,
				asset_image_path,
				asset_last_name,
				asset_phone_number,
				asset_phone_number_code,
				log_asset_id: asset_id,
				message_unique_id: getMessageUniqueId(),
				operating_asset_first_name,
				organization_id,
				workforce_id,
			},
		]
	}

	const timelineInline = formFieldsData => {
		let content = 'Form Submitted'
		let body = formData.form_name
		let data_entity_inline = formData?.data_entity_inline
			? JSON.parse(formData.data_entity_inline)
			: null
		return {
			mail_body: body,
			asset_reference: [
				{
					account_id: '',
					organization: '',
					asset_id: '',
					asset_first_name: '',
					asset_type_category_id: '',
					asset_last_name: '',
					asset_image_path: '',
				},
			],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			form_approval_field_reference: [],
			subject: body,
			attachments: [],
			content: content,
			form_id: formData.form_id,
			form_submitted: formFieldsData,
			form_flag_enable_data_secrecy: data_entity_inline
				? data_entity_inline.form_flag_enable_data_secrecy
				: null,
		}
	}

	const { data_type_id, field_id } = fieldList[0]
	const {
		activity_id,
		wf_due_date_days,
		form_id,
		form_workflow_activity_type_id,
	} = formData
	const { activity_type_id } = activityType
	const { activity_status_id } = activityStatus
	let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`

	let formFieldsData = Object.values(fields[form_id])
		.map(i => i.final)
		.filter(Boolean)

	if (data_type_id === 65) {
		const parseValue = fields[field_id].field_value
		title = parseValue.contact_name
	} else if (!![19, 20].includes(data_type_id)) {
		title = !!fields[form_id] && fields[form_id][field_id].field_value
		formFieldsData = Object.values(fields[form_id])
			.map(i => i.final)
			.filter(Boolean)
	}

	return {
		activity_id,
		activity_title: title,
		activity_description: title,
		activity_inline_data: JSON.stringify(formFieldsData),
		// data_entity_inline: JSON.stringify(formFieldsData),
		activity_timeline_collection: JSON.stringify(
			timelineInline(formFieldsData)
		),
		activity_participant_collection: JSON.stringify(participantInfo()),
		form_id: form_id,
		activity_form_id: form_id,
		workflow_activity_id: activity_id,
		activity_type_id,
		form_workflow_activity_type_id,
		// activity_type_id: isOrigin
		//   ? form_workflow_activity_type_id
		//   : activity_type_id,
		generated_group_account_name: '', // this should has to come from account name validation
		generated_account_code: '', // this should has to come from account code validation
		activity_title_expression: '', // this should has to come from account code validation
		gst_number: '', // this should has to come from account code validation
		pan_number: '', // this should has to come from account code validation
		activity_datetime_end:
			activity_id === 0
				? getAddToCurrentDateUTC(getCurrentDateUTC(), wf_due_date_days)
				: getCurrentDateUTC(), //utils.getCurrentDateUTC(),
		activity_datetime_start: getCurrentDateUTC(),
		activity_status_id,
	}
}

export const vilFieldConfigData = (field, fields) => {
	const processId = field.form_workflow_activity_type_id
	if (
		!!calcStub[processId] &&
		!!calcStub[processId][field.field_id] &&
		!!calcStub[processId][field.field_id].calculateValue
	) {
		const processField = calcStub[processId]
		let productValue = 0
		let quantity = 0
		let contractMonths = 0
		let aov = 0
		let conversionRate = 0
		let otc = 0
		let arc = 0
		const date = new Date()
		let dateOfClosure = date.toISOString().split('T')[0]
		if (!!processField.contractMonths) {
			const fieldId = processField.contractMonths.field_id
			contractMonths = fields[`${fieldId}`]
				? !!fields[`${fieldId}`].field_value.value
					? fields[`${fieldId}`].field_value.value
					: fields[`${fieldId}`].field_value
				: 0
		}
		if (!!processField.productCart) {
			let fieldId = processField.productCart.field_id
			if (fields[`${fieldId}`]) {
				const fieldValue = fields[`${fieldId}`].field_value
				productValue = !!fieldValue?.total
					? fieldValue.total
					: !!fieldValue?.productTotalData
					? fieldValue.productTotalData
					: 0
			}
		}
		if (!!processField.aov) {
			let fieldId = processField.aov.field_id
			aov = fields[`${fieldId}`] ? fields[`${fieldId}`].field_value : 0
		}
		if (!!processField.conversionRate) {
			let fieldId = processField.conversionRate.field_id
			conversionRate = fields[`${fieldId}`]
				? fields[`${fieldId}`].field_value
				: 0
		}
		if (!!processField.dateOfClosure) {
			let fieldId = processField.dateOfClosure.field_id
			dateOfClosure = fields[`${fieldId}`]
				? fields[`${fieldId}`].field_value
				: 0
		}
		if (!!processField.otc) {
			let fieldId = processField.otc.field_id
			otc = fields[`${fieldId}`] ? fields[`${fieldId}`].field_value : 0
		}
		if (!!processField.annualRecurringCharges) {
			let fieldId = processField.annualRecurringCharges.field_id
			arc = fields[`${fieldId}`] ? fields[`${fieldId}`].field_value : 0
		}

		const calculatedValue = processField[field.field_id].calculateValue({
			productValue,
			quantity,
			contractMonths,
			aov,
			conversionRate,
			dateOfClosure,
			otc,
			arc,
		})

		return calculatedValue
	} else {
		return 0
	}
}

export const processSubmittedFormAccess = async ({
	refinedWorkflowFormList,
	submittedFormList,
	statusBasedFormList = [],
	assetBasedFormList = [],
	visiblity,
}) => {
	const sortedList = refinedWorkflowFormList
		.map(workflowForm => {
			let selectedForm = submittedFormList.find(
				submittedForm => submittedForm.form_id === workflowForm.form_id
			)
			if (selectedForm && workflowForm.form_submission_type_id !== 2) {
				if (!!visiblity.activity_control_visibility) {
					if (
						visiblity.isOwner ||
						selectedForm.operating_asset_id === visiblity.operating_asset_id ||
						selectedForm.operating_asset_id === visiblity.asset_id
					) {
						return {
							...workflowForm,
							...selectedForm,
							canEdit: true,
							viewForm: true,
							isSubmitted: true,
						}
					} else {
						return {
							...workflowForm,
							...selectedForm,
							canEdit: false,
							viewForm: true,
							isSubmitted: true,
						}
					}
				} else {
					return {
						...workflowForm,
						...selectedForm,
						canEdit: visiblity.isWidgetActivityWorkspace
							? visiblity.isOwner
								? true
								: false
							: true,
						viewForm: visiblity.isWidgetActivityWorkspace ? true : false,
						isSubmitted: true,
					}
				}
			} else {
				return {
					...workflowForm,
					...selectedForm,
					isSubmitted: false,
					canEdit: false,
				}
			}
		})
		.filter(Boolean)
		.map(sortedForm => {
			if (
				!!sortedForm.form_flag_consolidation_enabled ||
				!!sortedForm.isSubmitted
			) {
				return sortedForm
			}
			if (statusBasedFormList.length > 0) {
				const mappedForm = statusBasedFormList.find(
					statusForm =>
						Number(statusForm.form_id) === Number(sortedForm.form_id)
				)
				if (!!mappedForm) {
					const isAccessible =
						mappedForm.delegated_to_assests &&
						mappedForm.delegated_to_assests.length > 0 &&
						mappedForm.delegated_to_assests.find(designate => {
							return designate.asset_id === visiblity.asset_id
						})
					return {
						...sortedForm,
						canEdit:
							visiblity.activity_lead_asset_id === visiblity.asset_id ||
							visiblity.isOwner
								? true
								: !!isAccessible,
					}
				}
			} else {
				if (assetBasedFormList.length > 0) {
					const accessibleForm = assetBasedFormList.find(
						accessForm =>
							Number(accessForm.form_id) === Number(sortedForm.form_id)
					)
					if (!!accessibleForm) {
						return {
							...sortedForm,
							canEdit: !!accessibleForm,
						}
					}
				} else {
					return {
						...sortedForm,
						canEdit: true,
					}
				}
			}
			return null
		})
		.filter(Boolean)
	return sortedList
}

export const buildFormData = async ({
	authData,
	workflowForm,
	activityType,
	activityStatus,
}) => {
	const jsonStr = {
		organization_id: authData.organization_id,
		account_id: authData.account_id,
		workforce_id: authData.workforce_id,
		asset_id: authData.asset_id,
		auth_asset_id: authData.asset_id,
		asset_token_auth: authData.asset_token_auth,
		activity_id: 0,
		activity_type_category_id: 9,
		activity_stream_type_id: 705,
		form_id: workflowForm.form_id,
		type: 'approval',
		statusType: [activityStatus],
		asset_first_name: authData.asset_first_name,
		asset_phone_number: authData.asset_phone_number,
		activityType: [activityType],
	}
	if (!!activityType && activityType.length > 0) {
		jsonStr.activity_type_id = activityType[0].activity_type_id
		jsonStr.activity_type_id_form = activityType[0].activity_type_id
		jsonStr.form_workflow_activity_type_id =
			workflowForm.form_workflow_activity_type_id
		jsonStr.wf_due_date_days =
			workflowForm.form_workflow_activity_type_default_duration_days
	} else {
		jsonStr.activity_type_id = workflowForm.form_workflow_activity_type_id
		jsonStr.activity_type_id_form = workflowForm.form_workflow_activity_type_id
		jsonStr.form_workflow_activity_type_id =
			workflowForm.form_workflow_activity_type_id
		jsonStr.wf_due_date_days = 0
	}
	return { ...jsonStr, ...workflowForm }
}

export const toConvertSentenceCase = theString => {
	let newString = theString
	//   .toLowerCase()
	//   .replace(/(^\s*\w|\si\s|[\.\!\?]\s*\w)/g, function (c) {
	//     return c.toUpperCase();
	//   });
	// newString = newString
	//   .split('\n')
	//   .map(item => item.charAt(0).toUpperCase() + item.slice(1))
	//   .join('\n');
	return newString
}

//arithmatic calculations:--

export const arithmaticCalcu = (field, fields, fieldList) => {
	try {
		let data = ''
		let dateDuration = field.field_inline_data.dateDiffDuration
		let multiplier = 0
		let list = fieldList.map(i => {
			return i.field_id
		})

		// if (!!field.data && !!field.data.field_value) {
		//   let json = JSON.parse(field.data.field_value)
		//   return !!json.value ? json.value : json
		// } else {
		field.field_inline_data.formula?.forEach(val => {
			let fieldValue = !!fields?.[val]?.field_value
				? fields[val].final.field_data_type_id === 18 &&
				  !!JSON.parse(fields[val].field_value)
					? JSON.parse(fields[val].field_value).value
						? JSON.parse(fields[val].field_value).value
						: fields?.[val]?.field_value
					: fields?.[val]?.field_value
				: { value: '' }
			if (list.includes(Number(val))) {
				// eslint-disable-next-line no-self-assign
				fieldValue = fieldValue
			} else {
				fieldValue = val
			}
			data =
				data +
				(fieldValue
					? typeof fieldValue === 'object'
						? fieldValue?.productTotalData
							? fieldValue?.productTotalData
							: fieldValue?.value?.toString()?.match(/[0-9]/)
							? fieldValue.value
							: 0
						: fieldValue
					: val)
		})
		// }
		for (var f in fields) {
			if (fields.hasOwnProperty(f)) {
				var val = fields[f]
				if (
					typeof val.field_value == 'object' &&
					val.field_value.hasOwnProperty('multiplier')
				) {
					if (val.field_value.multiplier !== 0) {
						multiplier = val.field_value.multiplier
					} else {
						multiplier = 0
					}
					console.log('multiplier', multiplier)
					data = `(${multiplier} * ${data})`
					return Math.round(eval(data))
				}
			}
		}

		if (
			field.data_type_id === 1 ||
			field.data_type_id === 2 ||
			field.data_type_id === 3 ||
			field.data_type_id === 4
		) {
			let res = []
			res = data.match(/\d{4}([\/.-])\d{2}\1\d{2}/g)
				? data.match(/\d{4}([\/.-])\d{2}\1\d{2}/g)
				: data.match(
						/[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/g
				  )
			if (res != null) {
				let date = new Date(res[0])
				let action = !!field.field_inline_data.formula
					? field.field_inline_data.formula[1]
					: null
				let num = !!field.field_inline_data.formula[2]
					? field.field_inline_data.formula[2]
					: null
				if (!!num) {
					date.setDate(
						eval(
							`${date.getDate()}${action}${
								!!fields[num]
									? !!fields[num].field_value
										? fields[num].field_value
										: 0
									: num
							}`
						)
					)
					let dd = String(date.getDate()).padStart(2, '0')
					let mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
					let yyyy = date.getFullYear()

					date = yyyy + '/' + mm + '/' + dd
					return new Date(date)
				}
			}
			return 0
		}

		if (!!dateDuration) {
			let res = []
			if (
				dateDuration === 'Days' ||
				dateDuration === 'Months' ||
				dateDuration === 'Weeks'
			)
				res = data.match(/\d{4}([\/.-])\d{2}\1\d{2}/g)
			else if (dateDuration === 'Hours' || dateDuration === 'Minutes') {
				res = data.match(
					/[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/g
				)
				if (!res) res = data.match(/\d{4}([\/.-])\d{2}\1\d{2}/g)
			}
			if (res) {
				res[0] = new Date(res[0])
				res[1] = new Date(res[1])
				const diffTime = Math.abs(res[0].getTime() - res[1].getTime())
				switch (dateDuration.toString()) {
					case 'Days':
						const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
						return diffDays
					case 'Months':
						const diffMonths = Math.floor(
							diffTime / (1000 * 60 * 60 * 24 * 7 * 4)
						)
						return diffMonths

					case 'Weeks':
						const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7))
						return diffWeeks
					case 'Hours':
						var diff = (res[0].getTime() - res[1].getTime()) / 1000
						diff /= 60 * 60
						return Math.abs(diff)
					case 'Minutes':
						var diff = (res[0].getTime() - res[1].getTime()) / 1000
						diff /= 60
						return Math.abs(Math.round(diff))
				}
			}
		}
		// console.log('data', data);
		// console.log('data', eval(data))

		return isFinite(eval(data)) ? eval(data) : 0
	} catch (err) {
		return 0
	}
}
