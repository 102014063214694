export const FILTER_FLAG = {
  APPLICATION: 0,
  GEOGRAPHY: 2,
  CREATOR: 6,
  TBCREATOR: 7,
  OPPURTUNITY: 8,
  SUB_APPLICATION: 20,
  SEGMENT: 21,
  CATEGORY: 22,
  FAMILY: 23,
  PRODUCT: 24,
  CLUSTER: 25,
  WORKFORCE_TAG: 26,
  WORKFORCE: 3,
  ACCOUNT: 27,
  VERTICAL: 28,
  CAMPAIGN: 30,
  KPI_PRODUCT: 44,
  KPI_CATEGORY: 45,
  KPI_TYPE: 46,
  ASSET_TAG1: 31,
  ASSET_TAG2: 32,
  ASSET_TAG3: 33,
  ASSET_TAG_TYPE1: 34,
  ASSET_TAG_TYPE2: 35,
  ASSET_TAG_TYPE3: 36,
  CLASSIFY_ROLES: 50,
  WORKFLOW: 57,
  CUSTOMER: 58,
  INDUSTRY: 59,
};

export const FILTER_ID = {
  APPLICATION: 1,
  CLUSTER: 2,
  GEOGRAPHY: 3,
  CREATOR: 4,
  SEGMENT: 5,
  WORKFORCE_TAG: 6,
  WORKFORCE: 26,
  OPPURTUNITY: 8,
  CATEGORY: 9,
  FAMILY: 10,
  PRODUCT: 11,
  ACCOUNT: 12,
  DATE_TYPE: 13,
  STATUS: 14,
  SUB_STATUS: 15,
  TIME_SCALE: 16,
  START_DATE: 17,
  END_DATE: 18,
  FORM: 19,
  FIELD: 20,
  PROCESS: 21,
  TIMELINE: 22,
  VERTICAL: 23,
  CAMPAIGN: 24,
  PERIOD_TYPE: 40,
  PERIOD: 41,
  FINANCIAL_YEAR: 42,
  KPI_PRODUCT: 44,
  KPI_CATEGORY: 45,
  KPI_TYPE: 46,
  ASSET_TAG1: 50,
  ASSET_TAG2: 51,
  ASSET_TAG3: 52,
};

export const HALF_YEARLY = [
  { label: 'H1 (APR-SEPT)', value: 2 }, //1
  { label: 'H2 (OCT-MAR)', value: 3 }, //2
];

export const QUATER = [
  { label: 'Q1 (APR-JUN)', value: 4 }, //1
  { label: 'Q2 (JUL-SEPT)', value: 5 }, //2
  { label: 'Q3 (OCT-DEC)', value: 6 }, //3
  { label: 'Q4 (JAN-MAR)', value: 7 }, //4
];

export const MONTHS = [
  { label: 'APRIL', value: 8 },  //4
  { label: 'MAY', value: 9 }, //5
  { label: 'JUNE', value: 10 }, //6
  { label: 'JULY', value: 11 }, //7
  { label: 'AUGUST', value: 12 }, //8
  { label: 'SEPTEMBER', value: 13 }, //9
  { label: 'OCTOBER', value: 14 }, //10
  { label: 'NOVEMBER', value: 15 }, //11
  { label: 'DECEMBER', value: 16 }, //12
  { label: 'JANUARY', value: 17 }, //1
  { label: 'FEBRUARY', value: 18 }, //2
  { label: 'MARCH', value: 19 }, //3
];
