// Workflow: Account
const panGSTFields = {
  panFields: [
    {
      form_id: 3778,
      form_name: 'LA Account Enlistment',
      field_id: 61867,
      field_name: 'PAN Number',
    },
    {
      form_id: 3779,
      form_name: 'GE Account Enlistment',
      field_id: 61910,
      field_name: 'PAN Number',
    },
    {
      form_id: 2810,
      form_name: 'SME Account Enlistment Request',
      field_id: 223767,
      field_name: 'PAN Number',
    },
    {
      form_id: 4678,
      form_name: 'SOHO Account Enlistment',
      field_id: 223882,
      field_name: 'PAN Number',
    },
    {
      form_id: 3780,
      form_name: 'Govt Account Enlistment',
      field_id: 225213,
      field_name: 'PAN Number',
    },
    {
      form_id: 50104,
      form_name: 'VICS Account Creation',
      field_id: 225144,
      field_name: 'PAN Number',
    },
  ],

  gstFields: [
    {
      form_id: 3778,
      form_name: 'LA Account Enlistment',
      field_id: 61865,
      pan_field_id: 61867,
      field_name:
        'GST Number (Enter Multiple GST Numbers using Comma Seperated)',
    },
    {
      form_id: 3779,
      form_name: 'GE Account Enlistment',
      field_id: 61908,
      pan_field_id: 61910,
      field_name:
        'GST Number (Enter Multiple GST Numbers using Comma Seperated)',
    },
    {
      form_id: 2810,
      form_name: 'SME Account Enlistment Request',
      field_id: 223768,
      pan_field_id: 223767,
      field_name:
        'GST Number (Enter Multiple GST Numbers using Comma Seperated)',
    },
    {
      form_id: 4678,
      form_name: 'SOHO Account Enlistment',
      field_id: 223884,
      pan_field_id: 223882,
      field_name:
        'GST Number (Enter Multiple GST Numbers using Comma Seperated)',
    },
    {
      form_id: 50104,
      form_name: 'VICS Account Creation',
      field_id: 225142,
      pan_field_id: 225144,
      field_name:
        'GST Number (Enter Multiple GST Numbers using Comma Seperated)',
    },
  ],
};

export default panGSTFields;
