/**
 * The function `validatePhoneNumber` checks if a given phone number is valid for a specific country
 * code.
 * @param {string} phoneNumber - The phoneNumber parameter is the phone number that needs to be validated.
 * @param {string} countryCode - The countryCode of the phone number starting with +.
 * @returns a boolean value indicating whether the phone number is valid or not.
 */
export const validatePhoneNumber = (phoneNumber, countryCode) => {
	let isValidPhone = true
	let regEx
	switch (countryCode) {
		case '+91':
			regEx = new RegExp(`^\\+91[1-9]\\d{9}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+61':
			regEx = new RegExp(`^\\+610[1-9]\\d{8}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+880':
			regEx = new RegExp(`^\\+880[1-9]\\d{9}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+1':
			regEx = new RegExp(`^\\+1[1-9]\\d{9}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+33':
			regEx = new RegExp(`^\\+33[1-9]\\d{8}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+49':
			regEx = new RegExp(`^\\+49[1-9]\\d{9,11}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+852':
			regEx = new RegExp(`^\\+852[1-9]\\d{7}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+44':
			regEx = new RegExp(`^\\+44[1-9]\\d{9}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+421':
			regEx = new RegExp(`^\\+421[1-9]\\d{9,11}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		case '+961':
			regEx = new RegExp(`^\\+961[1-9]\\d{9,11}$`, 'g')
			isValidPhone = regEx.test(phoneNumber)
			break
		default:
			break
	}
	return isValidPhone
}
