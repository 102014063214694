export const customEmailTemplate = params => {
  const {
    reviewUrl,
    org_image,
    org_name,
    formsForUpdate,
    formsForReview,
    recieverName,
    senderName,
    message,
    org_id,
  } = params;
  let image = `<img style='width: 30px' src='https://stagingoffice.greneos.com/grene-logo.png'/>`;
  let companySeal = `<p style=' color: #545454; display: block; font-family: Helvetica; font-size: 12px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; text-align: left;'> ${
    org_name || 'Grene Robotics Ltd. Pvt.'
  } </p>`;
  let org_signature = '';
  let businessLogo = `<img style='width: 80px; margin: 25px' src='https://stagingoffice.greneos.com/grene-logo.png' />`;
  if (org_id === 868) {
    image = `<img style="width: 110px;margin-left: 20px;"  src="https://staging.officedesk.app/vi_logo_large_white.png" alt="vi tm business logo"/>`;
    companySeal = `<p style=' color: #545454; display: block; font-family: Helvetica; font-size: 12px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0; text-align: left;'> Vi <sup><small>TM</small></sup> business </p> </td></tr>`;
    businessLogo = `<img style="width: 110px;margin-left: 20px;"  src="https://staging.officedesk.app/vi_logo_large_white.png" alt="vi tm business logo"/>`;
    org_signature = `<tr>
         <td style='color:#545454;padding-left: 50px;font-family: Helvetica;font-size: 10px;padding-bottom: 40px'>
            <strong>Vodafone Idea Limited</strong> (formerly Idea Cellular Limited)<br/>
            An Aditya Birla Group & Vodafone partnership
         </td>
      </tr>`;
  } else if (!!org_image) {
    image = `<img style='width: 30px' src=${org_image} />`;
  }
  let updateCTA = '';
  if (formsForUpdate.length > 0) {
    formsForUpdate.forEach(res => {
      updateCTA += `<a style='background: #f47920;display: inline-block;color: #FFFFFF;text-decoration: none;font-size: 12px;margin-top: 1.0em;background-clip: padding-box;padding: 5px 15px;box-shadow: 4px 4px 6px 1px #cbcbcb;margin-left:10px' target='_blank' class='blue-btn' href='${res.value}'>${res.label}</a> `;
    });
  }

  /*
    //  <img style='width: 80px; margin: 25px' src='https://stagingoffice.greneos.com/grene-logo.png' />
    */
  let formToView = reviewUrl
    ? `<a style='background: #707070;display: inline-block;color: #FFFFFF;text-decoration: none;font-size: 12px;margin-top: 1.0em;background-clip: padding-box;padding: 5px 15px;box-shadow: 4px 4px 6px 1px #cbcbcb;' target='_blank' class='blue-btn' href='${reviewUrl}'>${formsForReview.label}</a> `
    : '';
  let msg = message.split('\n').join('<br />');
  const template = `
            <table style='border-collapse: collapse !important;' width='100%' bgcolor='#ffffff' border='0' cellpadding='10' cellspacing='0'>
           <tbody>
              <tr>
                 <td>
                    <table bgcolor='#ffffff' style='width: 100%;max-width: 550px;' class='content' align='center' cellpadding='0' cellspacing='0' border='0'>
                       <tbody>
                          <tr>
                             <td align='center' valign='top'>
                                <table style='border: 1px solid #e2e2e2; border-radius: 4px; background-clip: padding-box; border-spacing: 0;' border='0' cellpadding='0' cellspacing='0' width='100%' id='templateContainer'>
                                   <tbody>
                                      <tr>
                                         <td align='left' style='float: right;' valign='top'>
                                            <div style="display: flex; justify-content: flex-start; width: 600px; height: 130px; ${
                                              org_id === 868
                                                ? 'background:#ee2737'
                                                : 'background-color: #e4e4e4'
                                            };">
                                              ${businessLogo}
                                            </div>
                                         </td>
                                      </tr>
                                      <tr>
                                         <td valign='top' style=' color: #505050; font-family: Helvetica; font-size: 14px; line-height: 150%; padding-top: 3.143em; padding-right: 3.5em; padding-left: 3.5em; padding-bottom: 1em; text-align: left;' class='bodyContent' mc:edit='body_content'>
                                            <p style='  display: block; font-family: Helvetica; font-size: 14px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-right: 0; margin-bottom: 15px; margin-left: 0; text-align: left;'>                            <strong>Dear ${recieverName},</strong>
                                            </p>
                                            <p style=' color: #545454; display: block; font-family: Helvetica; font-size: 14px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-right: 0; margin-bottom: 15px; margin-left: 0; text-align: left;'>
                                               ${msg}
                                            </p>
                                            ${formToView} 
                                            ${updateCTA}
                                            <p style=' color: #545454; display: block; font-family: Helvetica; font-size: 12px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 40px; margin-right: 0;  margin-left: 0; text-align: left;'> Regards, </p>
                                            <p style=' color: #545454; display: block; font-family: Helvetica; font-size: 12px; line-height: 1.500em; font-style: normal; font-weight: normal; letter-spacing: normal; margin-top: 2px; margin-right: 0; margin-bottom: 0px; margin-left: 0; text-align: left;'> ${senderName} </p>
                                            ${companySeal}
                                         </td>
                                      </tr>
                                      ${org_signature}
                                      <tr>
                                         <td style='padding: 40px;color: #c8c8c8;'>
                                            <p style='font-family: Helvetica;font-size: 9px;'>This E-Mail (including any attachments) may contain Confidential and/or legally privileged Information and is meant for the intended recipient(s) only. If you have received this e-mail in error and are not the intended recipient/s, kindly delete this e-mail immediately from your system. You are also hereby notified that any use, any form of reproduction, dissemination, copying, disclosure, modification, distribution and/or publication of this e-mail, its contents or its attachment/s other than by its intended recipient/s is strictly prohibited and may be construed unlawful. Internet Communications cannot be guaranteed to be secure or error-free as information could be delayed, intercepted, corrupted, lost, or may contain viruses. ${org_name} does not accept any liability for any errors, omissions, viruses or computer shutdown (s) or any kind of disruption/denial of services if any experienced by any recipient as a result of this e-mail.</p>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                                <!-- // END BODY -->
                             </td>
                          </tr>
                       </tbody>
                    </table>
                 </td>
              </tr>
           </tbody>
        </table>
  `;

  return template;
};
