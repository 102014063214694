const fieldEditConfig = {
  152184: {
    activity_type_id: 152184,
    activity_type_name: 'Business case workflow',
    4353: {
      form_id: 4353,
      form_name: 'Business Case Input',
      218385: {
        field_id: 218385,
        form_name: 'Upload business case',
      },
    },
  },
};

export default fieldEditConfig;
